import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { Typography } from '~/components';
import { getTheme } from '~/utils';

const sectionSpacing = getTheme('sectionSpacing');
const giantSpacing = getTheme('giantSpacing');
const mediumSpacing = getTheme('mediumSpacing');
const smallSpacing = getTheme('smallSpacing');
const inMobileAndTablet = getTheme('inMobileAndTablet');

export const Wrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  flex: 1;
  min-height: 20vh;
  align-items: center;
  margin: ${giantSpacing} ${sectionSpacing};
  @media ${inMobileAndTablet} {
    margin: ${giantSpacing} ${mediumSpacing};
  }
`;

export const QualityWrapper = styled.div`
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  margin: ${giantSpacing} 0;
  @media ${inMobileAndTablet} {
    margin: ${mediumSpacing} 0;
    flex-direction: column;
  }
`;

export const ServiceWrapper = styled(ScrollAnimation).attrs({
  animateIn: 'fadeInRight',
})`
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: ${sectionSpacing} 0;
`;

export const CapacityWrapper = styled(ScrollAnimation)`
  justify-content: center;
  width: 31%;
  @media ${inMobileAndTablet} {
    width: 100%;
    flex-direction: column;
    margin-bottom: ${mediumSpacing};
  }
`;
export const CapacityDownWrapper = styled(CapacityWrapper).attrs({
  animateIn: 'fadeInUp',
})``;
export const CapacityLeftWrapper = styled(CapacityWrapper).attrs({
  animateIn: 'fadeInLeft',
})``;
export const CapacityRightWrapper = styled(CapacityWrapper).attrs({
  animateIn: 'fadeInRight',
})``;

export const Title = styled(Typography).attrs({ variant: 'h4' })`
  white-space: nowrap;
  font-size: 38px;
  line-height: 38px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.75);
  letter-spacing: 2px;
  font-family: Poppins;
  text-align: center;
  margin-bottom: ${sectionSpacing};
  @media ${inMobileAndTablet} {
    margin-bottom: ${smallSpacing};
  }
`;

export const SubTitle = styled(Typography).attrs({ variant: 'h4' })`
  white-space: nowrap;
  font-size: 19px;
  line-height: 19px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  font-family: Poppins;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: ${sectionSpacing};
  @media ${inMobileAndTablet} {
    margin-bottom: ${smallSpacing};
  }
`;

type Props = {
  center?: boolean;
};

export const Text = styled(Typography).attrs({ variant: 'body1' })<Props>`
  white-space: normal;
  font-size: 17px;
  line-height: 29px;
  font-weight: 300;
  color: rgba(45, 48, 50, 0.75);
  letter-spacing: 0px;
  font-family: Poppins;
  text-align: ${({ center }) => (center ? 'center' : 'justify')};
  @media ${inMobileAndTablet} {
    text-align: justify;
  }
`;
