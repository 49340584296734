/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch, SetStateAction } from 'react';
import fb from 'firebase/app';
import 'firebase/messaging';

import { firebaseConfig } from '../config/firebaseConfig';

const { REACT_APP_FIREBASE_VAPID_KEY } = process.env;

export const firebase = !fb.apps.length
  ? fb.initializeApp(firebaseConfig)
  : fb.app();

let messaging: fb.messaging.Messaging | null = null;

if (fb.messaging.isSupported()) {
  messaging = firebase.messaging();
}

export const getToken = async (
  setTokenFound: Dispatch<SetStateAction<boolean>>,
) => {
  try {
    const token = await messaging?.getToken({
      vapidKey: REACT_APP_FIREBASE_VAPID_KEY,
    }); // get this here: Firebase / Project Settings / Cloud Messaging / Web Push Certificates
    if (token) {
      console.log('current token for client: ', token);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log(
        'No registration token available. Request permission to generate one.',
      );
      setTokenFound(false);
      // shows on the UI that permission is required
    }
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
    // catch error while creating client token
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging?.onMessage((payload) => {
      resolve(payload);
    });
  });
