import { GiHamburgerMenu, IoClose } from 'react-icons/all';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import { LogoDanieleImage } from '~/assets/images';
import { getTheme, ifStyle } from '~/utils';
import { Typography } from '..';

const smallSpacing = getTheme('smallSpacing');
const inDesktop = getTheme('inDesktop');
const inMobileAndTablet = getTheme('inMobileAndTablet');
const isOpenMenu = ifStyle('openMenu');
const isShadowMenu = ifStyle('shadowMenu');

type WrapperProps = {
  openMenu: boolean;
  shadowMenu: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex !important;
  flex-direction: row;
  position: fixed;
  top: 0;
  background-color: ${isShadowMenu('rgba(0, 0, 0, 0.4)', 'transparent')};
  width: 100%;
  height: 55px;
  z-index: 10;
  ${isOpenMenu(
    `
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  `,
    null,
  )}
`;
type Props = {
  openMenu: boolean;
};
export const LogoWrapper = styled.div<Props>`
  width: 60%;
  margin-left: 5px;
  background: url(${LogoDanieleImage}) no-repeat center left;
  background-size: 180px;
  @media ${inMobileAndTablet} {
    width: 85%;
  }
  ${isOpenMenu(
    `
      display: none !important;
  `,
    null,
  )}
`;
export const TextWrapper = styled.div<Props>`
  display: flex !important;
  flex-direction: row;
  width: 40%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  @media ${inMobileAndTablet} {
    justify-content: center;
    display: ${isOpenMenu('flex', 'none')} !important;
    flex-direction: column;
    width: ${isOpenMenu('100%', '100%')};
    height: 100%;
  }
`;
export const IconWrapper = styled.div<Props>`
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: ${isOpenMenu('flex-start', 'end')};
  margin-right: ${smallSpacing};
  @media ${inDesktop} {
    display: none !important;
    width: 40%;
    height: 100%;
  }
  ${isOpenMenu(
    `
      position: absolute;
      top: 23px;
      right: 12px;
      margin-right: 1.75rem;
  `,
    null,
  )}
`;

export const Text = styled(Typography).attrs({ variant: 'subtitle1' })`
  font-family: Poppins;
  line-height: 29px;
  font-weight: 300;
  @media ${inMobileAndTablet} {
    font-weight: 500;
    margin-bottom: 25px;
    font-size: 24px;
  }
`;

export const MenuIcon = styled(GiHamburgerMenu)`
  color: white;
`;

export const CloseMenuIcon = styled(IoClose)`
  color: white;
`;

export const Link = styled(DefaultLink)`
  color: white;
  text-decoration: none;
`;

export const Image = styled.img`
  width: 30%;
  height: 55px;
`;
