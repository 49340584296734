import React, { FC } from 'react';
import {
  ContactWrapper,
  FacebookIcon,
  IconWrapper,
  InstagramIcon,
  Link,
  LinkedinIcon,
  LocationIcon,
  MailIcon,
  NetworkWrapper,
  PhoneIcon,
  SingleContactWrapper,
  Text,
  TextWrapper,
  Title,
  WhatsappIcon,
  Wrapper,
} from './styles';

const FooterSection: FC = () => {
  return (
    <Wrapper>
      <ContactWrapper>
        <Title>Contato</Title>
        <SingleContactWrapper>
          <LocationIcon />
          <TextWrapper>
            <Text>
              Torre Business - R. das Orquídeas, 737 - sala 306 - Vila Bergamo,
              Indaiatuba - SP, 13330-000
            </Text>
          </TextWrapper>
        </SingleContactWrapper>
        <SingleContactWrapper>
          <PhoneIcon />
          <TextWrapper>
            <Link href="tel:19311532131">
              <Text>(19) 31153-2131</Text>
            </Link>
          </TextWrapper>
        </SingleContactWrapper>
        <SingleContactWrapper>
          <MailIcon />
          <TextWrapper>
            <Link href="mailto:contato@danielecarbonez.com.br">
              <Text>contato@danielecarbonez.com.br</Text>
            </Link>
          </TextWrapper>
        </SingleContactWrapper>
      </ContactWrapper>
      <NetworkWrapper>
        <Title>
          Rede <b>Sociais</b>
        </Title>
        <IconWrapper>
          <Link
            href="https://www.facebook.com/daniele.carbonez"
            target="_blank"
          >
            <FacebookIcon />
          </Link>
          <Link
            href="https://www.instagram.com/danielecarbonez_arquitetura/"
            target="_blank"
          >
            <InstagramIcon />
          </Link>
          <Link
            href="https://www.linkedin.com/company/daniele-carbonez-arquitetura/"
            target="_blank"
          >
            <LinkedinIcon />
          </Link>
          <Link
            href="https://api.whatsapp.com/send?phone=5519971593137&text=Olá, gostaria de um orçamento"
            target="_blank"
          >
            <WhatsappIcon />
          </Link>
        </IconWrapper>
      </NetworkWrapper>
    </Wrapper>
  );
};

export default FooterSection;
