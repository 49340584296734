import React, { FC } from 'react';
import Slider from 'react-slick';
import {
  Main1Image,
  Main2Image,
  Main3Image,
  Main4Image,
  Main5Image,
  Main6Image,
  Main7Image,
  Main8Image,
  Main9Image,
} from '~/assets/images';
import { Image, LogoWrapper, Wrapper } from './styles';

const settings = {
  infinite: true,
  autoplay: true,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const MainSection: FC = () => {
  return (
    <Wrapper id="MainSection">
      <LogoWrapper />
      <div style={{ maxWidth: '100%' }}>
        <Slider
          {...settings}
          autoplaySpeed={4000}
          lazyLoad="ondemand"
          arrows={false}
          fade
        >
          <Image alt="Projeto Daniele Carbonez" src={Main1Image} />
          <Image alt="Projeto Daniele Carbonez" src={Main2Image} />
          <Image alt="Projeto Daniele Carbonez" src={Main3Image} />
          <Image alt="Projeto Daniele Carbonez" src={Main4Image} />
          <Image alt="Projeto Daniele Carbonez" src={Main5Image} />
          <Image alt="Projeto Daniele Carbonez" src={Main6Image} />
          <Image alt="Projeto Daniele Carbonez" src={Main7Image} />
          <Image alt="Projeto Daniele Carbonez" src={Main8Image} />
          <Image alt="Projeto Daniele Carbonez" src={Main9Image} />
        </Slider>
      </div>
    </Wrapper>
  );
};

export default MainSection;
