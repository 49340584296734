import Firebase from 'firebase';

import { firebaseConfig } from '../config/firebaseConfig';

const { REACT_APP_ENV } = process.env;

if (!Firebase.apps?.length) {
  Firebase.initializeApp(firebaseConfig);
} else {
  Firebase.app(); // if already initialized, use that one
}
const analytics = Firebase.analytics();

if (REACT_APP_ENV === 'PROD') {
  analytics.setAnalyticsCollectionEnabled(true);
} else {
  analytics.setAnalyticsCollectionEnabled(false);
}

export default class Analytics {
  static logEvent(
    eventName: string,
    eventParams?: Firebase.analytics.EventParams,
    option?: Firebase.analytics.AnalyticsCallOptions,
  ): void {
    analytics.logEvent(eventName, eventParams, option);
  }
}
