import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { Button as DefaultButton, Typography } from '~/components';
import { getTheme } from '~/utils';

const sectionSpacing = getTheme('sectionSpacing');
const giantSpacing = getTheme('giantSpacing');
const minimumSpacing = getTheme('minimumSpacing');
const smallSpacing = getTheme('smallSpacing');
const modalRadius = getTheme('modalRadius');
const inMobileAndTablet = getTheme('inMobileAndTablet');

export const Wrapper = styled.div`
  flex: 1;
  min-height: 20vh;
  align-items: center;
  margin: ${giantSpacing} ${sectionSpacing};
  @media ${inMobileAndTablet} {
    margin: 0;
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography).attrs({ variant: 'h4' })`
  font-family: Poppins;
  font-size: 33px;
  line-height: 19px;
  font-weight: 300;
  text-align: center;
  margin-bottom: ${smallSpacing};
`;

export const Text = styled(Typography).attrs({ variant: 'body1' })`
  font-family: Poppins;
  line-height: 29px;
  font-weight: 300;
  color: rgba(45, 48, 50, 0.75);
  text-align: center;
`;

export const ImageWrapper = styled.div`
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${sectionSpacing};
  @media ${inMobileAndTablet} {
    flex-direction: column;
  }
`;

export const Image = styled.img`
  width: 100%;
  @media ${inMobileAndTablet} {
    margin-bottom: ${minimumSpacing};
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${sectionSpacing};
`;

export const Button = styled(DefaultButton)`
  border-radius: ${modalRadius};
`;

export const TextAnimation = styled(ScrollAnimation).attrs({
  animateIn: 'fadeInDown',
})`
  width: 100%;
`;
export const ImageSingleWrapper = styled(ScrollAnimation)`
  width: 100%;
`;
export const ImageDownWrapper = styled(ImageSingleWrapper).attrs({
  animateIn: 'fadeInUp',
})``;
export const ImageLeftWrapper = styled(ImageSingleWrapper).attrs({
  animateIn: 'fadeInLeft',
})``;
export const ImageRightWrapper = styled(ImageSingleWrapper).attrs({
  animateIn: 'fadeInRight',
})``;
