import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import DetailsProject from './DetailsProject';

const DetailsProjectContainer: FC = () => {
  const { projectId } = useParams<any>();

  return <DetailsProject projectIndex={projectId} />;
};

export default DetailsProjectContainer;
