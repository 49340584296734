import {
  CasaTwist01,
  CasaTwist010,
  CasaTwist011,
  CasaTwist012,
  CasaTwist013,
  CasaTwist014,
  CasaTwist015,
  CasaTwist016,
  CasaTwist017,
  CasaTwist018,
  CasaTwist019,
  CasaTwist02,
  CasaTwist020,
  CasaTwist021,
  CasaTwist022,
  CasaTwist023,
  CasaTwist024,
  CasaTwist025,
  CasaTwist026,
  CasaTwist027,
  CasaTwist028,
  CasaTwist029,
  CasaTwist03,
  CasaTwist030,
  CasaTwist031,
  CasaTwist032,
  CasaTwist033,
  CasaTwist034,
  CasaTwist035,
  CasaTwist036,
  CasaTwist037,
  CasaTwist038,
  CasaTwist039,
  CasaTwist04,
  CasaTwist040,
  CasaTwist041,
  CasaTwist042,
  CasaTwist043,
  CasaTwist044,
  CasaTwist045,
  CasaTwist046,
  CasaTwist047,
  CasaTwist048,
  CasaTwist049,
  CasaTwist05,
  CasaTwist06,
  CasaTwist07,
  CasaTwist08,
  CasaTwist09,
  ResidenciaColina01,
  ResidenciaColina010,
  ResidenciaColina011,
  ResidenciaColina012,
  ResidenciaColina013,
  ResidenciaColina014,
  ResidenciaColina015,
  ResidenciaColina016,
  ResidenciaColina017,
  ResidenciaColina018,
  ResidenciaColina019,
  ResidenciaColina02,
  ResidenciaColina020,
  ResidenciaColina021,
  ResidenciaColina03,
  ResidenciaColina04,
  ResidenciaColina05,
  ResidenciaColina06,
  ResidenciaColina07,
  ResidenciaColina08,
  ResidenciaColina09,
  ResidenciaCoutinho01,
  ResidenciaCoutinho02,
  ResidenciaCoutinho03,
  ResidenciaCoutinho04,
  ResidenciaCoutinho05,
  ResidenciaCoutinho06,
  ResidenciaCoutinho07,
  ResidenciaDiroma01,
  ResidenciaDiroma010,
  ResidenciaDiroma011,
  ResidenciaDiroma012,
  ResidenciaDiroma013,
  ResidenciaDiroma014,
  ResidenciaDiroma015,
  ResidenciaDiroma02,
  ResidenciaDiroma03,
  ResidenciaDiroma04,
  ResidenciaDiroma05,
  ResidenciaDiroma06,
  ResidenciaDiroma07,
  ResidenciaDiroma08,
  // ResidenciaDiroma09,
  ResidenciaELExterno01,
  ResidenciaELExterno02,
  ResidenciaELExterno03,
  ResidenciaELExterno04,
  ResidenciaELExterno05,
  ResidenciaELExterno06,
  ResidenciaELExterno07,
  ResidenciaELInterno01,
  ResidenciaELInterno010,
  ResidenciaELInterno011,
  ResidenciaELInterno012,
  ResidenciaELInterno013,
  ResidenciaELInterno014,
  ResidenciaELInterno015,
  ResidenciaELInterno016,
  ResidenciaELInterno017,
  ResidenciaELInterno018,
  ResidenciaELInterno019,
  ResidenciaELInterno02,
  ResidenciaELInterno020,
  ResidenciaELInterno03,
  ResidenciaELInterno04,
  ResidenciaELInterno05,
  ResidenciaELInterno06,
  ResidenciaELInterno07,
  ResidenciaELInterno08,
  ResidenciaELInterno09,
  ResidenciaGatti01,
  ResidenciaGatti02,
  ResidenciaGatti03,
  ResidenciaGatti04,
  ResidenciaGatti05,
  ResidenciaGatti06,
  ResidenciaGatti07,
  ResidenciaGatti08,
  ResidenciaJD01,
  ResidenciaJD02,
  ResidenciaJD03,
  ResidenciaJD04,
  ResidenciaJD05,
  ResidenciaJK01,
  ResidenciaJK010,
  ResidenciaJK011,
  ResidenciaJK012,
  ResidenciaJK013,
  ResidenciaJK02,
  ResidenciaJK03,
  ResidenciaJK04,
  ResidenciaJK05,
  ResidenciaJK06,
  ResidenciaJK07,
  ResidenciaJK08,
  ResidenciaJK09,
  ResidenciaManhatan01,
  ResidenciaManhatan010,
  ResidenciaManhatan02,
  ResidenciaManhatan03,
  ResidenciaManhatan04,
  ResidenciaManhatan05,
  ResidenciaManhatan06,
  // ResidenciaManhatan07,
  ResidenciaManhatan08,
  ResidenciaManhatan09,
  ResidenciaMartinelli01,
  ResidenciaMartinelli010,
  ResidenciaMartinelli011,
  ResidenciaMartinelli012,
  ResidenciaMartinelli013,
  ResidenciaMartinelli014,
  ResidenciaMartinelli015,
  ResidenciaMartinelli016,
  ResidenciaMartinelli017,
  ResidenciaMartinelli018,
  ResidenciaMartinelli02,
  ResidenciaMartinelli03,
  ResidenciaMartinelli04,
  ResidenciaMartinelli05,
  ResidenciaMartinelli06,
  ResidenciaMartinelli07,
  ResidenciaMartinelli08,
  ResidenciaMartinelli09,
  ResidenciaPrimavera01,
  ResidenciaPrimavera02,
  ResidenciaPrimavera03,
  ResidenciaPrimavera04,
  ResidenciaPrimavera05,
  ResidenciaPrimavera06,
  ResidenciaPrimavera07,
  ResidenciaPrimavera08,
  ResidenciaPrimavera09,
  ResidenciaRL01,
  ResidenciaRL02,
  ResidenciaRL03,
  ResidenciaRL04,
  ResidenciaRL05,
  ResidenciaRL06,
  ResidenciaRL07,
  ResidenciaRL08,
  ResidenciaSchiavon01,
  ResidenciaSchiavon010,
  ResidenciaSchiavon02,
  ResidenciaSchiavon03,
  ResidenciaSchiavon04,
  ResidenciaSchiavon05,
  ResidenciaSchiavon06,
  ResidenciaSchiavon07,
  ResidenciaSchiavon08,
  ResidenciaSchiavon09,
  ResidenciaTS01,
  ResidenciaTS010,
  ResidenciaTS02,
  ResidenciaTS03,
  ResidenciaTS04,
  ResidenciaTS05,
  ResidenciaTS06,
  ResidenciaTS07,
  ResidenciaTS08,
  ResidenciaTS09,
} from '~/assets/images';

export const data = [
  {
    image: ResidenciaColina01,
    title: 'RESIDÊNCIA COLINA',
    condominium: 'Condomínio Terra Cota - Indaiatuba',
    area: '',
    description: `
      Com uma vista incrível para a área verde, o grande diferencial dessa residência é a topografia em aclive, que proporcionou uma valorização no projeto e muita imponência.
      Para aproveitar todos os espaços a casa foi projetada em vários platôs, aumentando a sensação de amplitude.
      Para deixar a casa ampla e ventilada projetamos grandes vãos, integrando toda a residência.
    `,
    photos: [
      ResidenciaColina02,
      ResidenciaColina03,
      ResidenciaColina04,
      ResidenciaColina05,
      ResidenciaColina06,
      ResidenciaColina07,
      ResidenciaColina08,
      ResidenciaColina09,
      ResidenciaColina010,
      ResidenciaColina011,
      ResidenciaColina012,
      ResidenciaColina013,
      ResidenciaColina014,
      ResidenciaColina015,
      ResidenciaColina016,
      ResidenciaColina017,
      ResidenciaColina018,
      ResidenciaColina019,
      ResidenciaColina020,
      ResidenciaColina021,
    ],
  },
  {
    image: ResidenciaELExterno01,
    title: 'RESIDÊNCIA EL',
    condominium: 'Condomínio Villa dos Pinheiros – Indaiatuba - SP',
    area: '',
    description: `
      A casa foi projetada para ter a integração dos ambientes sociais voltadas para a piscina e para uma linda área verde. Os elementos e matérias naturais valorizaram muito a estética da casa.
      A decoração também segue o mesmo estilo contemporâneo, com linhas retas, muitos elementos naturais e peças de designers brasileiros que focam na funcionalidade, praticidade e conforto.
    `,
    photos: [
      ResidenciaELExterno02,
      ResidenciaELExterno03,
      ResidenciaELExterno04,
      ResidenciaELExterno05,
      ResidenciaELExterno06,
      ResidenciaELExterno07,
      ResidenciaELInterno01,
      ResidenciaELInterno02,
      ResidenciaELInterno03,
      ResidenciaELInterno04,
      ResidenciaELInterno05,
      ResidenciaELInterno06,
      ResidenciaELInterno07,
      ResidenciaELInterno08,
      ResidenciaELInterno09,
      ResidenciaELInterno010,
      ResidenciaELInterno011,
      ResidenciaELInterno012,
      ResidenciaELInterno013,
      ResidenciaELInterno014,
      ResidenciaELInterno015,
      ResidenciaELInterno016,
      ResidenciaELInterno017,
      ResidenciaELInterno018,
      ResidenciaELInterno019,
      ResidenciaELInterno020,
    ],
  },
  {
    image: ResidenciaGatti01,
    title: 'RESIDÊNCIA GATTI',
    condominium: 'Condomínio Jardim Primavera III – Salto - SP',
    area: '',
    description: `
    Com uma estrutura construtiva mista em alvenaria e estrutura metálica, essa casa confere um toque totalmente moderno e sustentável. A Arquitetura proporcionou muitos ambientes especiais.
    Para deixar o projeto ainda mais especial, essa residência contempla um lago artificial, totalmente sustentável, integrado à área gourmet.
    `,
    photos: [
      ResidenciaGatti02,
      ResidenciaGatti03,
      ResidenciaGatti04,
      ResidenciaGatti05,
      ResidenciaGatti06,
      ResidenciaGatti07,
      ResidenciaGatti08,
    ],
  },
  {
    image: ResidenciaJK02,
    title: 'RESIDÊNCIA JK',
    condominium: 'Condomínio Helvetia Park - Indaiatuba',
    area: '',
    description: `
      Essa linda residência possui uma fachada incrível e moderna. A casa foi projetada para o bem-estar e funcionalidade, com ambientes versáteis e integrados. A estrutura metálica, pedra natural e brises fazem uma composição harmônica na fachada.
      Aproveitamos todas as curvas de níveis do terreno, deixando a residência alta e proporcionando uma vista privilegiada.
      A área gourmet foi integrada com todos os ambientes sociais, voltada para uma vista incrível, transformando o espaço em uma área confortável para reunir a família e os amigos.
    `,
    photos: [
      ResidenciaJK01,
      ResidenciaJK03,
      ResidenciaJK04,
      ResidenciaJK05,
      ResidenciaJK06,
      ResidenciaJK07,
      ResidenciaJK08,
      ResidenciaJK09,
      ResidenciaJK010,
      ResidenciaJK011,
      ResidenciaJK012,
      ResidenciaJK013,
    ],
  },
  {
    image: ResidenciaPrimavera04,
    title: 'RESIDÊNCIA PRIMAVERA',
    condominium: 'Condomínio Primavera - Salto',
    area: '',
    description: `
      Uma casa moderna com uma fachada imponente, os vidros e a estrutura metálica proporcionaram leveza a estética da residência. O terreno amplo proporcionou um belo paisagismo valorizando todos os espaços e vistas.
    `,
    photos: [
      ResidenciaPrimavera01,
      ResidenciaPrimavera02,
      ResidenciaPrimavera03,
      ResidenciaPrimavera04,
      ResidenciaPrimavera05,
      ResidenciaPrimavera06,
      ResidenciaPrimavera07,
      ResidenciaPrimavera08,
      ResidenciaPrimavera09,
    ],
  },
  {
    image: ResidenciaRL01,
    title: 'RESIDÊNCIA RL',
    condominium: 'Condomínio Terra Cota - Indaiatuba',
    area: '',
    description: `
      Residência contemporânea, com grandes aberturas e materiais naturais. O grande desafio foi otimizar vários espaços especiais em um terreno de 6m de declive, por esse motivo a casa tem uma estica alta e imponente.
      Para aproveitar as curvas do terreno, projetamos um sobrado invertido com a área social no pavimento inferior. A casa tem uma estética alta proporcionando uma vista linda para a área verde do condomínio.
    `,
    photos: [
      ResidenciaRL02,
      ResidenciaRL03,
      ResidenciaRL04,
      ResidenciaRL05,
      ResidenciaRL06,
      ResidenciaRL07,
      ResidenciaRL08,
    ],
  },
  {
    image: ResidenciaSchiavon01,
    title: 'RESIDÊNCIA SCHIAVON',
    condominium: 'London Park - Indaiatuba',
    area: '',
    description: `
      Com uma fachada grande e imponente, utilizamos elementos com linhas retas e materiais naturais, os brises desenvolvem um papel importante na fachada, dando proteção ao sol da tarde.
      A área social é totalmente integrada com a cozinha, sala e gourmet, otimizando os espaços e modernizando a estética final.
    `,
    photos: [
      ResidenciaSchiavon02,
      ResidenciaSchiavon03,
      ResidenciaSchiavon04,
      ResidenciaSchiavon05,
      ResidenciaSchiavon06,
      ResidenciaSchiavon07,
      ResidenciaSchiavon08,
      ResidenciaSchiavon09,
      ResidenciaSchiavon010,
    ],
  },
  {
    image: ResidenciaTS01,
    title: 'RESIDÊNCIA TS',
    condominium: 'Condomínio terracota - Indaiatuba',
    area: '',
    description: `
      Projetamos essa residência com estruturas mistas como alvenaria e uma estrutura metálica dando uma leveza na estética e permitindo grandes vãos.  Grande parte da residência foi pensada no bem-estar da família e no conforto térmico e acústico.
      O grande diferencial desse projeto é a cobertura metálica inclinada, valorizando as formas e dando uma volumetria diferenciada.
    `,
    photos: [
      ResidenciaTS02,
      ResidenciaTS03,
      ResidenciaTS04,
      ResidenciaTS05,
      ResidenciaTS06,
      ResidenciaTS07,
      ResidenciaTS08,
      ResidenciaTS09,
      ResidenciaTS010,
    ],
  },
  {
    image: ResidenciaDiroma010,
    title: 'RESIDÊNCIA DI ROMA',
    condominium: 'Condomínio Di Roma - Indaiatuba',
    area: '',
    description: `
    Essa residência tem uma área social totalmente integrada e funcional, dando amplitude nos espaços e trazendo um conforto diferenciado.
    Priorizamos muitos elementos naturais como a composição da pedra com a madeira.
    `,
    isMobilePhoto: true,
    photos: [
      ResidenciaDiroma01,
      ResidenciaDiroma02,
      ResidenciaDiroma03,
      ResidenciaDiroma04,
      ResidenciaDiroma05,
      ResidenciaDiroma06,
      ResidenciaDiroma07,
      ResidenciaDiroma08,
      // ResidenciaDiroma09,
      ResidenciaDiroma011,
      ResidenciaDiroma012,
      ResidenciaDiroma013,
      ResidenciaDiroma014,
      ResidenciaDiroma015,
    ],
  },
  {
    image: ResidenciaCoutinho07,
    title: 'RESIDÊNCIA COUTINHO',
    condominium: 'Condomínio Dona Maria José - Indaiatuba',
    area: '',
    description: `
      A decoração dessa sala foi projetada para acolher a família, com a sofisticação do design contemporâneo e com um toque industrial. Os materiais que prevaleceram nessa decoração foram madeira, metalon e concreto.
      Os quadros e o cimento queimado tomaram o lugar do convencional painel de madeira.
    `,
    isMobilePhoto: true,
    photos: [
      ResidenciaCoutinho01,
      ResidenciaCoutinho02,
      ResidenciaCoutinho03,
      ResidenciaCoutinho04,
      ResidenciaCoutinho05,
      ResidenciaCoutinho06,
    ],
  },
  {
    image: ResidenciaManhatan01,
    title: 'RESIDÊNCIA MANHATAN',
    condominium: 'Edifício Manhathan - Indaiatuba',
    area: '',
    description: `
      Todos os ambientes foram projetados e pensados para o dia a dia dos moradores. Utilizamos painéis em madeira e peças decorativas confortáveis e arrojadas, que deram todo o conforto e acolhimento em cada local.
      Para ampliar os ambientes utilizamos os jogos de espelhos, valorizando a decoração.
    `,
    isMobilePhoto: true,
    photos: [
      ResidenciaManhatan02,
      ResidenciaManhatan03,
      ResidenciaManhatan04,
      ResidenciaManhatan05,
      ResidenciaManhatan06,
      // ResidenciaManhatan07,
      ResidenciaManhatan08,
      ResidenciaManhatan09,
      ResidenciaManhatan010,
    ],
  },
  {
    image: ResidenciaJD02,
    title: 'Residência JD',
    condominium: 'Condomínio Villa Inglesa - Indaiatuba',
    area: '',
    description: `
      Para revitalizar completamente o espaço de lazer da família e dar-lhe um ar mais luxuoso, trabalhamos acima e além do conceito original da casa, recuperando a madeira do forro e acrescentando uma variedade de materiais harmônicos e coloridos.
      A substituição dos revestimentos da piscina e da área externa ampliaram o ambiente, proporcionando uma área de lazer incrível.
    `,
    photos: [
      ResidenciaJD01,
      ResidenciaJD02,
      ResidenciaJD03,
      ResidenciaJD04,
      ResidenciaJD05,
    ],
  },
  {
    image: CasaTwist01,
    title: 'RESIDÊNCIA TWIST',
    condominium: 'Condomínio Maison Du Parc - Indaiatuba',
    area: '',
    description: `
      Todos os ambientes foram pensados em harmonia, priorizando elementos naturais. Os grandes vãos e o pé direito duplo conferem a sensação de amplitude, ventilação e uma boa iluminação natural que favorece o interior da residência.
      Para alcançar um visual contemporâneo, utilizamos elementos e peças com linhas retas que traduzem toda a beleza e elegância ao resultado. Tudo isso dentro de uma abordagem de design orientada para a família.
    `,
    photos: [
      CasaTwist01,
      CasaTwist02,
      CasaTwist03,
      CasaTwist04,
      CasaTwist05,
      CasaTwist06,
      CasaTwist07,
      CasaTwist08,
      CasaTwist09,
      CasaTwist010,
      CasaTwist011,
      CasaTwist012,
      CasaTwist013,
      CasaTwist014,
      CasaTwist015,
      CasaTwist016,
      CasaTwist017,
      CasaTwist018,
      CasaTwist019,
      CasaTwist020,
      CasaTwist021,
      CasaTwist022,
      CasaTwist023,
      CasaTwist024,
      CasaTwist025,
      CasaTwist026,
      CasaTwist027,
      CasaTwist028,
      CasaTwist029,
      CasaTwist030,
      CasaTwist031,
      CasaTwist032,
      CasaTwist033,
      CasaTwist034,
      CasaTwist035,
      CasaTwist036,
      CasaTwist037,
      CasaTwist038,
      CasaTwist039,
      CasaTwist040,
      CasaTwist041,
      CasaTwist042,
      CasaTwist043,
      CasaTwist044,
      CasaTwist045,
      CasaTwist046,
      CasaTwist047,
      CasaTwist048,
      CasaTwist049,
    ],
  },
  {
    image: ResidenciaMartinelli03,
    title: 'RESIDÊNCIA MATANELLI',
    condominium: 'Condomínio Amistalden - Indaiatuba',
    area: '',
    description: `
      Residência com uma estética clean e moderna, esse projeto foi pensado na funcionalidade e no bem-estar da família.
      A integração da área gourmet com a cozinha proporcionou uma bancada em ilha integrada, atendendo a demanda da família que gosta de receber os amigos. Na área externa, mantemos tons quentes e para criar espaços especiais o pergolado em madeira, expressando charme e elegância.
    `,
    photos: [
      ResidenciaMartinelli01,
      ResidenciaMartinelli02,
      ResidenciaMartinelli03,
      ResidenciaMartinelli04,
      ResidenciaMartinelli05,
      ResidenciaMartinelli06,
      ResidenciaMartinelli07,
      ResidenciaMartinelli08,
      ResidenciaMartinelli09,
      ResidenciaMartinelli010,
      ResidenciaMartinelli011,
      ResidenciaMartinelli012,
      ResidenciaMartinelli013,
      ResidenciaMartinelli014,
      ResidenciaMartinelli015,
      ResidenciaMartinelli016,
      ResidenciaMartinelli017,
      ResidenciaMartinelli018,
    ],
  },
];
