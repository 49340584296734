import { action, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { AuthApi } from '~/api';
import { Storage } from '~/services';

export default class UserStore {
  @persist('object')
  @observable
  userData: User = {
    email: '',
    name: '',
    id: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  login = async (credentials: Credentials): Promise<void> => {
    const fakeToken = { access_token: 'token' };
    Storage.setToken(fakeToken); // just for route testing
    const token = await AuthApi.login(credentials);
    Storage.setToken(token);
  };

  @action
  logout = (): void => {
    this.userData = { email: '', name: '', id: 0 };
    Storage.clearWholeStorage();
  };
}
