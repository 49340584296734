import React, { FC } from 'react';
import Slider from 'react-slick';
import { Service1Image, Service2Image } from '~/assets/images';
import { Loading } from '~/components';
import { Sections } from '~/utils';
import {
  AboutSection,
  DanieleSection,
  MainSection,
  ProjectSection,
  ServiceSection,
} from './Sections';
import { Footer, Header, Image, Wrappers } from './styles';

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

type Props = {
  onProjects: () => void;
  handleSections(nameSection: Sections): void;
  refAbout: any;
  refService: any;
  loading: boolean;
};

const Home: FC<Props> = ({
  onProjects,
  handleSections,
  refAbout,
  refService,
  loading,
}) => {
  if (loading) return <Loading />;
  return (
    <div>
      <Wrappers>
        <Header handleSections={handleSections} />
        <MainSection />
        <AboutSection wrapperRef={refAbout} />
        <DanieleSection />
        <ProjectSection onProjects={onProjects} />
        <div style={{ maxWidth: '100%', padding: '10px 0px' }}>
          <Slider {...settings} autoplaySpeed={5000} arrows={false}>
            <div>
              <Image alt="teste" src={Service1Image} />
            </div>
            <div>
              <Image alt="teste" src={Service2Image} />
            </div>
          </Slider>
        </div>
        <ServiceSection wrapperRef={refService} />
        <Footer />
      </Wrappers>
    </div>
  );
};

export default Home;
