import React, { FC } from 'react';
import HubspotForm from 'react-hubspot-form';
import {
  ContentWrapper,
  Footer,
  FormWrapper,
  Header,
  MapWrapper,
  Text,
  TextWrapper,
  Title,
  Wrapper,
} from './styles';

const Contact: FC = () => (
  <Wrapper>
    <Header shadow />
    <TextWrapper>
      <Title>Contato</Title>
      <Text>
        Projetamos para traduzir desejos e criar lugares que contam histórias,
        por isso nossa arquitetura é voltada para e celebrar momentos felizes.
        Tratamos cada projeto de forma exclusiva, com ambientes pensados para
        atender seus anseios e necessidades. Se você procura um projeto único,
        exclusivo, que celebre sua personalidade envie um e-mail para
        comercial@danielecarbonez.com.br
      </Text>
    </TextWrapper>
    <ContentWrapper>
      <FormWrapper>
        <HubspotForm
          portalId="22066752"
          formId="d8bea171-aa08-4793-aca3-0b787c2990ac"
          loading={<div>Aguarde...</div>}
        />
      </FormWrapper>
      <MapWrapper>
        <iframe
          title="Daniele Carbonez Arquitetura"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14680.17032826327!2d-47.2235238!3d-23.095537!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7af993ac1681aa71!2sDaniele%20Carbonez%20Arquitetura!5e0!3m2!1sen!2sbr!4v1644220437543!5m2!1sen!2sbr"
          width="620"
          height="550"
          style={{ border: 0 }}
          allowFullScreen={false}
          loading="lazy"
        />
      </MapWrapper>
    </ContentWrapper>
    <Footer />
  </Wrapper>
);

export default Contact;
