import React, { FC, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Routes } from '~/routes';
import { Sections } from '~/utils/enums';
import Home from './Home';

const HomeContainer: FC<any> = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const refAbout = useRef(null);
  const refService = useRef(null);

  const scrollToRef = (ref: any): void => {
    window.scrollTo({
      top: ref.current.offsetTop - 55,
      behavior: 'smooth',
    });
  };

  const handleSections = (nameSection: Sections): void => {
    switch (nameSection) {
      case Sections.ABOUT:
        scrollToRef(refAbout);
        break;
      case Sections.SERVICE:
        scrollToRef(refService);
        break;
      default:
        break;
    }
  };

  const onProjects = (): void => {
    history.push(Routes.PROJECTS);
  };

  useEffect(() => {
    if (history.location.hash === '#about' && !loading)
      handleSections(Sections.ABOUT);
    if (history.location.hash === '#service' && !loading)
      handleSections(Sections.SERVICE);
    setTimeout(() => {
      setLoading(false);
    }, 1800);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  return (
    <Home
      onProjects={onProjects}
      handleSections={handleSections}
      loading={loading}
      refAbout={refAbout}
      refService={refService}
    />
  );
};

export default withRouter(HomeContainer);
