import React, { FC } from 'react';
import { TextInputType } from '~/utils/types/Inputs';
import { FormError } from '..';
import { Input, InputWrapper } from './styles';

const TextInput: FC<TextInputType> = ({
  error = '',
  onChange,
  onBlur,
  onFocus,
  inputProps,
  maxlength,
  variant = 'standard',
  rows,
  ...rest
}) => (
  <InputWrapper {...rest}>
    <FormError error={error}>
      <Input
        {...rest}
        margin="normal"
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        error={!!error}
        inputProps={{ maxLength: maxlength, ...inputProps }}
        variant={variant}
        minRows={rows}
      />
    </FormError>
  </InputWrapper>
);

export default TextInput;
