import ReactLoading from 'react-loading';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex !important;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 11;
`;

export const LoadingCircular = styled(ReactLoading)`
  z-index: 11;
`;
