import React, { VFC, useEffect, useState } from 'react';
import { data } from './data';
import Projects from './Projects';

const ProjectsContainer: VFC = () => {
  const [loading, setLoading] = useState(true);
  const [counter, setCounter] = useState(0);
  const imageLoaded = () => {
    setCounter(counter + 1);
    if (counter >= data.length - 1) setLoading(false);
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1800);
  }, []);
  return <Projects imageLoaded={imageLoaded} loading={loading} />;
};

export default ProjectsContainer;
