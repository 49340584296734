import styled from 'styled-components';
import { getTheme } from '~/utils';

const inMobileAndTablet = getTheme('inMobileAndTablet');

export const Wrapper = styled.div`
  @media ${inMobileAndTablet} {
    display: none;
  }
`;
