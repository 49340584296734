import React, { FC } from 'react';
import {
  ContentWrapper,
  Text,
  TextWrapper,
  Title,
  TitleWrapper,
  Wrapper,
} from './styles';

type Props = {
  wrapperRef?: any;
};
const AboutSection: FC<Props> = ({ wrapperRef }) => {
  return (
    <Wrapper ref={wrapperRef}>
      <ContentWrapper>
        <TitleWrapper>
          <Title>QUEM SOMOS</Title>
        </TitleWrapper>
        <TextWrapper>
          <Text>
            Escritório de Arquitetura e interiores gerenciado por Daniele
            Carbonez, com o propósito de criar e implementar o estilo
            contemporâneo, vem se tornando referência em projetos exclusivos na
            região.
          </Text>

          <Text>
            Criado no ano de 2018, o escritório está situado na cidade de
            Indaiatuba - interior de São Paulo, atuando em toda a região. Conta
            com um time multidisciplinar que atua em projetos e gerenciamento de
            arquitetura e interiores.
          </Text>

          <Text>
            A equipe tem como foco manter os princípios de qualidade, buscando
            sempre o melhor resultado, voltado à experiência e a satisfação de
            cada cliente.
          </Text>
          <Text>
            Em todo o projeto assinado buscamos traduzir os verdadeiros desejos,
            respeitando a identidade e a singularidade, que é transmitida
            através de linhas retas, traços arrojados, simplicidade e
            sofisticação das formas.
          </Text>
        </TextWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default AboutSection;
