import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { Footer as DefaultFooter, Header as DefaultHeader } from '~/components';
import { getTheme } from '~/utils';

const primaryMain = getTheme('brand.primary.main');

export const Wrappers = styled(ScrollAnimation).attrs({
  animateIn: 'fadeIn',
})`
  flex: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: ${primaryMain};
  margin: 0;
  flex-direction: column;
`;

export const Header = styled(DefaultHeader)``;

export const Footer = styled(DefaultFooter)``;

export const Image = styled.img`
  width: 100%;
  height: 90vh;
  object-fit: cover;
`;
