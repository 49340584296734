import React, { FC } from 'react';
import { data } from '../Projects/data';
import {
  ContentWrapper,
  Footer,
  Grid,
  Header,
  Image,
  ImageWrapper,
  MainImageWrapper,
  StoryWrapper,
  Text,
  TextContent,
  TextWrapper,
  Title,
  TitleWrapper,
  Wrapper,
} from './styles';

type Props = {
  projectIndex: number;
};

const Projects: FC<Props> = ({ projectIndex }) => {
  const project = data[projectIndex];
  return (
    <Wrapper>
      <Header shadow />
      <TitleWrapper>
        <Title>{project.title}</Title>
      </TitleWrapper>
      <StoryWrapper>
        <MainImageWrapper>
          <Image
            src={`${project.image}`}
            srcSet={`${project.image}`}
            alt={project.title}
            loading="lazy"
          />
        </MainImageWrapper>
        <TextContent>
          <TextWrapper>
            <Text>{project.condominium}</Text>
            <Text>{project.area}</Text>
            <Text>{project.description}</Text>
          </TextWrapper>
        </TextContent>
      </StoryWrapper>
      <ContentWrapper>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          sm={12}
        >
          {project.photos &&
            project.photos.map((image) => (
              <Grid
                item
                sm={11}
                md={project.isMobilePhoto ? 6 : 12}
                key={`image${project.title}`}
              >
                <ImageWrapper>
                  <Image
                    src={`${image}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={`Imagem do projeto ${project.title}`}
                    loading="lazy"
                  />
                </ImageWrapper>
              </Grid>
            ))}
        </Grid>
      </ContentWrapper>
      <Footer />
    </Wrapper>
  );
};

export default Projects;
