import styled from 'styled-components';
import { getTheme } from '~/utils';

const inDesktop = getTheme('inDesktop');

export const Wrapper = styled.div`
  @media ${inDesktop} {
    display: none;
  }
`;
