import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { Typography } from '~/components';
import { getTheme } from '~/utils';

const mediumSpacing = getTheme('mediumSpacing');
const sectionSpacing = getTheme('sectionSpacing');
const giantSpacing = getTheme('giantSpacing');
const inMobileAndTablet = getTheme('inMobileAndTablet');

export const Wrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin: ${giantSpacing} ${sectionSpacing};
  @media ${inMobileAndTablet} {
    margin: ${mediumSpacing};
  }
`;

export const ContentWrapper = styled(ScrollAnimation).attrs({
  animateIn: 'fadeInUp',
})`
  display: flex !important;
  flex-direction: row;
  min-height: 20vh;
  @media ${inMobileAndTablet} {
    flex-direction: column;
    justify-content: center;
  }
`;

export const TitleWrapper = styled.div`
  width: 40%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  @media ${inMobileAndTablet} {
    width: 100%;
    margin: ${sectionSpacing} 0;
  }
`;
export const TextWrapper = styled.div`
  justify-content: center;
  align-items: center;
  width: 60%;
  @media ${inMobileAndTablet} {
    width: 100%;
  }
`;

export const Title = styled(Typography).attrs({ variant: 'h4' })`
  font-family: Poppins;
  font-size: 33px;
  line-height: 19px;
  font-weight: 300;
  text-align: center;
`;

export const Text = styled(Typography).attrs({ variant: 'body1' })`
  font-family: Poppins;
  line-height: 29px;
  font-weight: 300;
  margin-top: ${mediumSpacing};
  color: rgba(45, 48, 50, 0.75);
  @media ${inMobileAndTablet} {
    text-align: justify;
  }
`;
