import styled from 'styled-components';
import { Grid as DefaultGrid } from '@material-ui/core';
import {
  Footer as DefaultFooter,
  Header as DefaultHeader,
  Typography,
} from '~/components';
import { getTheme } from '~/utils';

const inMobileAndTablet = getTheme('inMobileAndTablet');
const sceneSpacing = getTheme('sceneSpacing');
const smallSpacing = getTheme('smallSpacing');
const mediumSpacing = getTheme('mediumSpacing');
const primaryMain = getTheme('brand.primary.main');

export const Wrapper = styled.div`
  @media ${inMobileAndTablet} {
    /*
  * responsive styles
  */
  }
  width: 100%;
  height: 100%;
  background-color: ${primaryMain};
  margin: 0;
  flex-direction: column;
`;
export const ContentWrapper = styled.div`
  margin-top: 12vh;
  padding: 0 ${sceneSpacing};
  @media ${inMobileAndTablet} {
    padding: 0;
    margin-top: ${sceneSpacing};
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 80px 0;
  @media ${inMobileAndTablet} {
    margin: 0;
    padding: 80px ${mediumSpacing};
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  @media ${inMobileAndTablet} {
    margin: 0;
    padding: 80px ${mediumSpacing};
  }
`;

export const Header = styled(DefaultHeader)``;

export const Footer = styled(DefaultFooter)``;

export const Title = styled(Typography).attrs({ variant: 'h4' })`
  line-height: 22px;
  font-weight: 300;
  letter-spacing: 3px;
  color: rgba(0, 0, 0, 0.89);
  font-family: Poppins;
  text-align: center;
  white-space: nowrap;
  margin-bottom: ${smallSpacing};
`;

export const Text = styled(Typography).attrs({ variant: 'body1' })`
  width: 100%;
  color: rgba(45, 48, 50, 0.75);
  line-height: 30px;
  font-weight: 400;
  font-family: Poppins;
  text-align: justify;
  margin: 0 auto;
  @media ${inMobileAndTablet} {
    text-align: justify;
    width: 100%;
    margin: 0;
  }
`;

export const ImageWrapper = styled.div`
  display: flex !important;
  position: relative !important;
  border: none !important;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const LegendWrapper = styled.div`
  background: white;
  position: absolute;
  width: 100%;
  bottom: 0px;
  z-index: 2;
  padding: ${mediumSpacing};
`;

export const Image = styled.img`
  height: 100%;
  max-height: 90vh;
  width: 100%;
  transition: all 0.4s ease-in-out;
  transform: scale(1);
  object-fit: cover;
  :hover {
    z-index: 2;
    transform: scale(1.02);
  }
  @media ${inMobileAndTablet} {
    object-fit: cover;
  }
`;

export const Grid = styled(DefaultGrid)`
  display: flex !important;
`;

export const StoryWrapper = styled.div`
  display: flex !important;
  position: relative !important;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 0 ${sceneSpacing};
  @media ${inMobileAndTablet} {
    padding: 0 ${mediumSpacing};
    flex-direction: column-reverse;
  }
`;
export const MainImageWrapper = styled.div`
  display: flex !important;
  position: relative !important;
  flex-direction: row;
  width: 50%;
  height: auto;
  @media ${inMobileAndTablet} {
    display: none !important;
  }
`;
export const TextContent = styled.div`
  display: flex !important;
  position: relative !important;
  flex-direction: row;
  width: 48%;
  height: 100%;
  @media ${inMobileAndTablet} {
    width: 100%;
  }
`;
