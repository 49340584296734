import React, { VFC } from 'react';
import { LoadingCircular, Wrapper } from './styles';

const Loading: VFC = () => {
  return (
    <Wrapper>
      <LoadingCircular type="spinningBubbles" color="white" />
    </Wrapper>
  );
};

export default Loading;
