import React, { FC, useEffect, useState } from 'react';
import { Sections } from '~/utils';
import { Touchable } from '..';

import {
  CloseMenuIcon,
  IconWrapper,
  Link,
  LogoWrapper,
  MenuIcon,
  Text,
  TextWrapper,
  Wrapper,
} from './styles';

type Props = {
  shadow?: boolean;
  handleSections?(name: Sections): void;
};

const Header: FC<Props> = ({ shadow, handleSections }) => {
  const [scrolling, setScrolling] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [shadowMenu, setShadowMenu] = useState(false);

  const onScroll = (e: any) => setScrolling(e.target.documentElement.scrollTop);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (!shadow) {
      const maxHeight = window.innerHeight - 55;
      if (window.scrollY > maxHeight) setShadowMenu(true);
      if (shadowMenu && window.scrollY <= maxHeight) setShadowMenu(false);
    }
  }, [scrolling, shadowMenu, shadow]);

  return (
    <Wrapper openMenu={openMenu} shadowMenu={shadow || shadowMenu}>
      <LogoWrapper openMenu={openMenu} />
      <TextWrapper openMenu={openMenu}>
        <Link
          to="/#about"
          onClick={() => {
            handleSections ? handleSections(Sections.ABOUT) : null;
            setOpenMenu(false);
          }}
        >
          <Text>Sobre</Text>
        </Link>
        <Link to="/projects" onClick={() => setOpenMenu(false)}>
          <Text>Projetos</Text>
        </Link>
        <Link
          to="/#service"
          onClick={() => {
            handleSections ? handleSections(Sections.SERVICE) : null;
            setOpenMenu(false);
          }}
        >
          <Text>Serviços</Text>
        </Link>
        <Link to="/contact" onClick={() => setOpenMenu(false)}>
          <Text>Contato</Text>
        </Link>
      </TextWrapper>
      <IconWrapper openMenu={openMenu}>
        <Touchable onPress={() => setOpenMenu(!openMenu)}>
          {!openMenu ? <MenuIcon /> : <CloseMenuIcon />}
        </Touchable>
      </IconWrapper>
    </Wrapper>
  );
};

export default Header;
