import React from 'react';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { StylesProvider } from '@material-ui/core/styles';
import { Toastify } from '~/modules';
import { ScrollToTop } from './components';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import WebPush from './services/push';
import * as serviceWorker from './serviceWorker';
import store from './stores';
import { breakpoints, colors, radius, spacings } from './theme';
import GlobalStyle from './theme/global';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'animate.css/animate.min.css';

const routingStore = new RouterStore();
const theme = {
  ...colors,
  ...spacings,
  ...radius,
  ...breakpoints,
};
const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, routingStore);

const stores = {
  ...store,
  routing: routingStore,
};

render(
  <ThemeProvider theme={theme}>
    <Provider {...stores}>
      <Router history={history}>
        <Toastify.ToastContainer />
        <WebPush />
        <ScrollToTop>
          <StylesProvider injectFirst>
            <Routes />
          </StylesProvider>
          <GlobalStyle />
        </ScrollToTop>
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
