import styled from 'styled-components';
import { getTheme, pxToRem } from '~/utils';

const backgroundZ0 = getTheme('background.z0');
const largeSpacing = getTheme('spacing.lg');

export const ChildrenWrapper = styled.div`
  background-color: ${backgroundZ0};
  padding: ${largeSpacing};
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Constrain = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: ${pxToRem(650)};
`;
