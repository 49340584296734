import { darken, lighten } from 'polished';

const primary = '#ffffff';
const secondary = '#2B9FBB';
const tertiary = '#f0557f';
const accent = '#262f47';
const disabled = '#414042';

const colors: { brand: Colors } = {
  brand: {
    text: '#212121',
    info: '#4096D1',
    success: '#357a38',
    warning: '#F5B800',
    failure: '#cc0000',
    disabled: {
      light: lighten(0.05, disabled),
      main: disabled,
      dark: darken(0.3, disabled),
      contrast: '#cccccc',
    },
    primary: {
      light: lighten(0.05, primary),
      main: primary,
      dark: darken(0.12, primary),
      contrast: '#000000',
    },
    secondary: {
      light: lighten(0.05, secondary),
      main: secondary,
      dark: darken(0.1, secondary),
      contrast: '#f6f6f6',
    },
    tertiary: {
      light: lighten(0.15, tertiary),
      main: tertiary,
      dark: darken(0.1, tertiary),
      contrast: '#eaeded',
    },
    accent: {
      light: lighten(0.05, accent),
      main: accent,
      dark: darken(0.05, accent),
      contrast: '#f9f9f9',
    },
  },
};

export default colors;
