import React, { FC, MouseEventHandler } from 'react';
import { LinkProps as LinkBaseProps } from '@material-ui/core';

import { CustomLink } from './styles';

type LinkProps = Omit<LinkBaseProps, 'onClick'> & {
  onPress?: MouseEventHandler;
};
const Link: FC<LinkProps> = ({ onPress, children, ...rest }) => {
  return (
    <CustomLink onClick={onPress} {...rest}>
      {children}
    </CustomLink>
  );
};

export default Link;
