import {
  AiOutlineMail,
  BiPhone,
  BsInstagram,
  BsLinkedin,
  FaFacebookF,
  GoLocation,
  ImWhatsapp,
} from 'react-icons/all';
import styled from 'styled-components';
import { Link as DefaultLink, Typography } from '~/components';
import { getTheme } from '~/utils';

const sectionSpacing = getTheme('sectionSpacing');
const smallSpacing = getTheme('smallSpacing');
const mediumSpacing = getTheme('mediumSpacing');
const giantSpacing = getTheme('giantSpacing');
const inMobileAndTablet = getTheme('inMobileAndTablet');

export const Wrapper = styled.div`
  display: flex !important;
  flex-direction: row;
  flex: 1;
  margin: ${giantSpacing} ${sectionSpacing};
  padding-bottom: ${sectionSpacing};
  @media ${inMobileAndTablet} {
    margin: ${mediumSpacing};
    flex-direction: column;
  }
`;

export const ContactWrapper = styled.div`
  width: 60%;
  height: 100%;
  @media ${inMobileAndTablet} {
    width: 100%;
  }
`;
export const NetworkWrapper = styled.div`
  width: 35%;
  height: 100%;
  @media ${inMobileAndTablet} {
    width: 100%;
    margin-top: ${mediumSpacing};
  }
`;
export const IconWrapper = styled.div`
  display: flex !important;
  flex-direction: row;
  width: 100%;
`;

export const SingleContactWrapper = styled.div`
  display: flex !important;
  flex-direction: row;
  margin-top: ${smallSpacing};
`;

export const TextWrapper = styled.div`
  display: flex !important;
  flex-direction: row;
  width: 80%;
  justify-content: left;
`;

export const Title = styled(Typography).attrs({ variant: 'h4' })`
  font-family: Poppins;
  font-size: 33px;
  line-height: 21px;
  font-weight: 300;
  letter-spacing: 1px;
  color: rgba(10, 10, 10, 0.81);
  margin-bottom: ${mediumSpacing};
`;

export const Text = styled(Typography).attrs({ variant: 'body1' })`
  font-family: Poppins;
  line-height: 29px;
  font-weight: 300;
  color: rgba(45, 48, 50, 0.75);
`;

export const InstagramIcon = styled(BsInstagram).attrs({ size: 35 })`
  margin-right: ${mediumSpacing};
`;

export const FacebookIcon = styled(FaFacebookF).attrs({ size: 35 })`
  margin-right: ${smallSpacing};
`;

export const LinkedinIcon = styled(BsLinkedin).attrs({ size: 35 })`
  margin-right: ${mediumSpacing};
`;

export const LocationIcon = styled(GoLocation).attrs({ size: 35 })`
  margin-right: ${mediumSpacing};
`;

export const PhoneIcon = styled(BiPhone).attrs({ size: 35 })`
  margin-right: ${mediumSpacing};
`;

export const MailIcon = styled(AiOutlineMail).attrs({ size: 35 })`
  margin-right: ${mediumSpacing};
`;

export const WhatsappIcon = styled(ImWhatsapp).attrs({ size: 35 })`
  margin-right: ${mediumSpacing};
`;

export const Link = styled(DefaultLink)`
  color: #000;
`;
