import React, { FC } from 'react';
import { Project1Image, Project2Image, Project3Image } from '~/assets/images';

import {
  Button,
  ButtonWrapper,
  Image,
  ImageDownWrapper,
  ImageLeftWrapper,
  ImageRightWrapper,
  ImageWrapper,
  Text,
  TextAnimation,
  TextWrapper,
  Title,
  Wrapper,
} from './styles';

type Props = {
  onProjects: () => void;
};

const ProjectSection: FC<Props> = ({ onProjects }) => {
  return (
    <Wrapper>
      <TextWrapper>
        <TextAnimation>
          <Title>Projetos</Title>
          <Text>Conheça nossos últimas criações</Text>
        </TextAnimation>
        <ImageWrapper>
          <ImageLeftWrapper>
            <Image src={Project1Image} />
          </ImageLeftWrapper>
          <ImageDownWrapper>
            <Image src={Project2Image} />
          </ImageDownWrapper>
          <ImageRightWrapper>
            <Image src={Project3Image} />
          </ImageRightWrapper>
        </ImageWrapper>
      </TextWrapper>
      <ButtonWrapper>
        <Button onPress={onProjects} hasBorder>
          Ver Mais
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ProjectSection;
