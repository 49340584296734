import React, { VFC } from 'react';
import { DanieleImage } from '~/assets/images';
import {
  ContentWrapper,
  Image,
  ImageWrapper,
  Text,
  TextWrapper,
  Wrapper,
} from './styles';

const DanieleSection: VFC = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <TextWrapper>
          <Text>
            &quot;Tenho muito orgulho e clareza da carreira que venho
            construindo e sei com propriedade para onde quero que ela me leve.
          </Text>
          <Text>
            A minha experiência é fruto de toda a minha criatividade,
            fundamentada não só no exercício contínuo da prática profissional,
            mas também na ousadia e na pesquisa constante para a construção de
            um repertório.
          </Text>
          <Text>
            São das pessoas e dos meus clientes que extraio toda a inspiração e
            criatividade.
          </Text>
          <Text>
            Sou grata por trabalhar com pessoas que valorizam uma arquitetura
            única e por formar uma equipe dedicada e habilidosa, envolvida no
            compromisso de realizar uma arquitetura exclusiva e surpreendente,
            que vai além do contemporâneo.
          </Text>

          <Text>
            Juntos construímos a personalidade do Escritório Daniele
            Carbonez.&quot; - Arquiteta Daniele Carbonez
          </Text>
        </TextWrapper>
        <ImageWrapper>
          <Image src={DanieleImage} />
        </ImageWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default DanieleSection;
