import styled from 'styled-components';
import { OnlyLogoImage } from '~/assets/images';
import { getTheme } from '~/utils';

const inMobileAndTablet = getTheme('inMobileAndTablet');

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100vh;
  @media ${inMobileAndTablet} {
    /* max-height: 50vh; */
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  transition: all 0.4s ease-in-out;
  transform: scale(1);
  animation: leaves 3.8s ease-in-out infinite alternate;
  @media ${inMobileAndTablet} {
    object-fit: cover;
  }
`;

export const LogoWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 65vh;
  left: 39%;
  right: 39%;
  background: url(${OnlyLogoImage}) no-repeat top left;
  background-size: 25%;
  z-index: 5;
  @media ${inMobileAndTablet} {
    width: auto;
    left: 15%;
    right: 15%;
    background-size: 100%;
  }
`;
