import React from 'react';
import { inject, observer } from 'mobx-react';
import { Switch } from 'react-router-dom';
import { Contact, DetailsProject, Home, Projects } from '~/scenes';
import Public from './public';
import { Routes } from './routing';

const RoutesContainer: React.FC = () => (
  <Switch>
    <Public exact path={Routes.HOME} component={Home} />
    <Public exact path={Routes.CONTACT} component={Contact} />
    <Public exact path={Routes.PROJECTS} component={Projects} />
    <Public path={Routes.DETAILS_PROJECTS} component={DetailsProject} />
  </Switch>
);

export { Routes };
export default inject('routing')(observer(RoutesContainer));
