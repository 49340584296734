import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { Grid as DefaultGrid } from '@material-ui/core';
import {
  Footer as DefaultFooter,
  Header as DefaultHeader,
  Link as DefaultLink,
  Typography,
} from '~/components';
import { getTheme } from '~/utils';

const inMobileAndTablet = getTheme('inMobileAndTablet');
const sceneSpacing = getTheme('sceneSpacing');
const smallSpacing = getTheme('smallSpacing');
const mediumSpacing = getTheme('mediumSpacing');
const primaryMain = getTheme('brand.primary.main');

export const Wrapper = styled.div`
  @media ${inMobileAndTablet} {
    /*
  * responsive styles
  */
  }
  width: 100%;
  height: 100%;
  background-color: ${primaryMain};
  margin: 0;
  flex-direction: column;
`;
export const ContentWrapper = styled.div`
  /* align-items: center; */
  flex-grow: 1;
  padding: 0 ${sceneSpacing};
  @media ${inMobileAndTablet} {
    padding: 0;
  }
`;

export const TextWrapper = styled(ScrollAnimation).attrs({
  animateIn: 'fadeInUp',
})`
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 80px 0;
  @media ${inMobileAndTablet} {
    margin: 0;
    padding: 80px ${mediumSpacing};
  }
`;

export const Header = styled(DefaultHeader)``;

export const Footer = styled(DefaultFooter)``;

export const Title = styled(Typography).attrs({ variant: 'h4' })`
  font-family: Poppins;
  line-height: 19px;
  font-weight: 300;
  text-align: center;
  margin-bottom: ${smallSpacing};
`;

export const Text = styled(Typography).attrs({ variant: 'body1' })`
  width: 50%;
  margin: 0 auto;
  font-family: Poppins;
  line-height: 29px;
  font-weight: 300;
  color: rgba(45, 48, 50, 0.75);
  text-align: center;
  @media ${inMobileAndTablet} {
    text-align: justify;
    width: 100%;
    margin: 0;
  }
`;

export const Legend = styled(Typography).attrs({ variant: 'body1' })`
  width: 100%;
  font-family: Poppins;
  line-height: 29px;
  font-weight: 300;
  color: #000;
`;

export const ImageWrapper = styled.div`
  display: flex !important;
  position: relative !important;
  border: none !important;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
export const HoverWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.8, 0.09, 1, 1);
  :hover {
    animation: bounce;
    transition: all 0.4s cubic-bezier(0.8, 0.09, 1, 1);
    opacity: 1;
  }
`;

export const LegendWrapper = styled.div`
  background: white;
  position: absolute;
  width: 100%;
  bottom: 0px;
  z-index: 2;
  padding: ${mediumSpacing};
  @media ${inMobileAndTablet} {
    min-width: 100%;
  }
`;

export const Image = styled.img`
  height: 30vh;
  width: 100%;
  transition: all 0.4s ease-in-out;
  transform: scale(1);
  object-fit: cover;
  :hover {
    transform: scale(1.02);
  }
  @media ${inMobileAndTablet} {
    object-fit: cover;
    height: 300px;
    min-width: 100%;
  }
  @media (min-width: 1100px) and (max-width: 1300px) {
    height: 30vh;
  }
  @media (min-width: 1300px) {
    height: 40vh;
  }
`;

export const Grid = styled(DefaultGrid)`
  display: flex !important;
`;
export const Link = styled(DefaultLink)`
  width: 100%;
`;
