/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { get } from 'lodash';

export const getTheme = (themeProp: string) => ({ theme }: any): string =>
  get(theme, themeProp);

const brandPrimary = getTheme('primary.main');
const brandSecondary = getTheme('secondary.main');
const brandTertiary = getTheme('tertiary.main');
const brandAccent = getTheme('accent.main');
const infoMain = getTheme('info.main');
const dangerMain = getTheme('danger.main');
const warningMain = getTheme('warning.main');
const brandPrimaryContrast = getTheme('primary.contrast');
const brandSecondaryContrast = getTheme('secondary.contrast');
const brandTertiaryContrast = getTheme('tertiary.contrast');
const brandAccentContrast = getTheme('accent.contrast');
const infoContrast = getTheme('info.contrast');
const dangerContrast = getTheme('danger.contrast');
const warningContrast = getTheme('warning.contrast');

export const getBackgroundColor = (props: any): string => {
  if (props.disabled) {
    return `${brandPrimary(props)}70`;
  }
  switch (props.variant) {
    case 'primary':
      return brandPrimary(props);
    case 'secondary':
      return brandSecondary(props);
    case 'tertiary':
      return brandTertiary(props);
    case 'accent':
      return brandAccent(props);
    case 'info':
      return infoMain(props);
    case 'warning':
      return warningMain(props);
    case 'danger':
      return dangerMain(props);
    default:
      return brandPrimary(props);
  }
};

export const getTextColor = (props: any): string => {
  if (props.disabled) {
    return brandPrimaryContrast(props);
  }
  switch (props.variant) {
    case 'primary':
      return brandPrimaryContrast(props);
    case 'secondary':
      return brandSecondaryContrast(props);
    case 'tertiary':
      return brandTertiaryContrast(props);
    case 'accent':
      return brandAccentContrast(props);
    case 'danger':
      return dangerContrast(props);
    case 'info':
      return infoContrast(props);
    case 'warning':
      return warningContrast(props);
    default:
      return brandPrimaryContrast(props);
  }
};
