import { Firebase } from '~/modules';

const remoteConfig = Firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 0;

export default class RemoteConfigService {
  static async setup(params: DefaultParamsRemoteConfig): Promise<void> {
    remoteConfig.defaultConfig = params;
    await remoteConfig.fetchAndActivate();
  }

  static async fetch(): Promise<void> {
    return remoteConfig.fetch();
  }

  static async getValue(key: string): Promise<string> {
    return remoteConfig.getValue(key).asString();
  }

  static async getValueJson(key: string): Promise<any> {
    return remoteConfig.getValue(key).asString()
      ? JSON.parse(remoteConfig.getValue(key).asString())
      : '';
  }

  static async getBoolean(key: string): Promise<boolean> {
    return remoteConfig.getBoolean(key);
  }
}
