/* eslint-disable no-console */
/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useState } from 'react';
import { showAlert } from '~/utils';
import { getToken, onMessageListener } from './firebase';

const pushContainer: FC = () => {
  const [isTokenFound, setTokenFound] = useState(false);

  console.warn('token found: ', isTokenFound);

  getToken(setTokenFound);

  onMessageListener()
    .then((payload: any) => {
      showAlert({
        position: 'top-right',
        message: `${payload.notification.title}: ${payload.notification.body}`,
      });
      console.log(payload);
    })
    .catch((err) => console.log('failed: ', err));

  return null;
};

export default pushContainer;
