import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import {
  Button as DefaultButton,
  Footer as DefaultFooter,
  Header as DefaultHeader,
  TextInput,
  Typography,
} from '~/components';
import { getTheme } from '~/utils';

const inMobileAndTablet = getTheme('inMobileAndTablet');
const giantSpacing = getTheme('giantSpacing');
const sceneSpacing = getTheme('sceneSpacing');
const mediumSpacing = getTheme('mediumSpacing');
const smallSpacing = getTheme('smallSpacing');
const primaryMain = getTheme('brand.primary.main');
const modalRadius = getTheme('modalRadius');

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${primaryMain};
  margin: 0;
  flex-direction: column;
  @media ${inMobileAndTablet} {
    /*
  * responsive styles
  */
  }
`;
export const ContentWrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex !important;
  flex-direction: row;
  padding: 0 ${sceneSpacing};
  @media ${inMobileAndTablet} {
    flex-direction: column;
    padding: 0 ${mediumSpacing};
  }
`;

export const FormWrapper = styled.div`
  width: 50%;
  margin-right: ${giantSpacing};
  @media ${inMobileAndTablet} {
    margin-right: 0px;
    width: 100%;
    padding-bottom: ${sceneSpacing};
  }
`;

export const MapWrapper = styled.div`
  width: 60%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  @media ${inMobileAndTablet} {
    width: 100%;
    padding-bottom: ${sceneSpacing};
  }
`;

export const TextWrapper = styled(ScrollAnimation).attrs({
  animateIn: 'fadeInUp',
})`
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0 ${sceneSpacing};
  margin: 80px 0;
`;

export const Header = styled(DefaultHeader)``;

export const Footer = styled(DefaultFooter)``;

export const Input = styled(TextInput).attrs({ variant: 'outlined' })`
  width: 95%;
  @media ${inMobileAndTablet} {
    width: 100%;
  }
`;

export const Title = styled(Typography).attrs({ variant: 'h4' })`
  font-family: Poppins;
  font-size: 33px;
  line-height: 19px;
  font-weight: 300;
  text-align: center;
  margin-bottom: ${smallSpacing};
`;

export const Text = styled(Typography).attrs({ variant: 'body1' })`
  width: 80%;
  font-family: Poppins;
  line-height: 29px;
  font-weight: 300;
  color: rgba(45, 48, 50, 0.75);
  margin: 0 auto;
  text-align: justify;
  @media ${inMobileAndTablet} {
    text-align: justify;
    width: 100%;
    padding: 0 ${mediumSpacing};
  }
`;

export const Button = styled(DefaultButton)`
  border-radius: ${modalRadius};
`;
