import React, { FC } from 'react';
import {
  CapacityDownWrapper,
  CapacityLeftWrapper,
  CapacityRightWrapper,
  QualityWrapper,
  ServiceWrapper,
  SubTitle,
  Text,
  Title,
  Wrapper,
} from './styles';

type Props = {
  wrapperRef?: any;
};
const ServiceSection: FC<Props> = ({ wrapperRef }) => {
  return (
    <Wrapper ref={wrapperRef}>
      <ServiceWrapper>
        <Title>
          O QUE <b>FAZEMOS</b>
        </Title>
        <Text center>
          Projetos que traduzem desejos, proporcionam bem-estar e conforto,
          realizando formas e sonhos.
        </Text>
      </ServiceWrapper>
      <QualityWrapper>
        <CapacityLeftWrapper>
          <SubTitle>PROJETOS RESIDÊNCIAIS</SubTitle>
          <Text>
            Cada projeto tem um estilo único, elaborado exclusivamente para cada
            cliente. Alinhamos funcionalidade com a estética, fazendo da casa um
            local bonito e agradável para se viver. Nossos projetos contam as
            histórias de nossos clientes, expressando os desejos de suas
            famílias.
          </Text>
        </CapacityLeftWrapper>
        <CapacityDownWrapper>
          <SubTitle>INTERIORES</SubTitle>
          <Text>
            Elaboramos projetos de interiores residenciais, criando e
            acompanhando a implantação do início ao fim. Cada projeto é tratado
            de forma única oferecendo personalidade aos ambientes. O lar deve
            ser confortável, um verdadeiro refúgio, local onde a família possa
            se reunir, dividir sua história e seus momentos, por isso os
            ambientes devem refletir a personalidade de seus moradores.
          </Text>
        </CapacityDownWrapper>
        <CapacityRightWrapper>
          <SubTitle>GESTÃO DE PROJETOS</SubTitle>
          <Text>
            Nos envolvemos com o processo do início ao fim, para que a execução
            tenha qualidade e aconteça conforme o previsto. O comprometimento
            com as etapas de um projeto garante que cada ambiente criado seja
            aproveitado ao máximo.
          </Text>
        </CapacityRightWrapper>
      </QualityWrapper>
    </Wrapper>
  );
};

export default ServiceSection;
