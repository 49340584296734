import React, { FC } from 'react';
import { If, Loading } from '~/components';
import { data } from './data';
import {
  ContentWrapper,
  Footer,
  Grid,
  Header,
  HoverWrapper,
  Image,
  ImageWrapper,
  Legend,
  LegendWrapper,
  Link,
  Text,
  TextWrapper,
  Title,
  Wrapper,
} from './styles';

type Props = {
  loading: boolean;
  imageLoaded(): void;
};

const Projects: FC<Props> = ({ loading, imageLoaded }) => {
  return (
    <>
      <If condition={!loading}>
        <Wrapper>
          <Header shadow />
          <TextWrapper>
            <Title>Projetos</Title>
            <Text>
              É um fato conhecido de todos que um leitor se distrairá com o
              conteúdo de texto legível de uma página quando estiver examinando
              sua diagramação.
            </Text>
          </TextWrapper>
          <ContentWrapper>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={0}
              sm={12}
            >
              {data.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item sm={12} md={4} key={`image${index}`}>
                  <Link href={`/projects/${index}`}>
                    <ImageWrapper>
                      <Image
                        src={`${item.image}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${item.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        decoding="async"
                        onLoad={imageLoaded}
                        loading="lazy"
                      />
                      <HoverWrapper>
                        <LegendWrapper>
                          <Legend>{item.title}</Legend>
                        </LegendWrapper>
                      </HoverWrapper>
                    </ImageWrapper>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </ContentWrapper>
          <Footer />
        </Wrapper>
      </If>
      <If condition={loading}>
        <Loading />
      </If>
    </>
  );
};

export default Projects;
